import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/SideBar/SideBar';
import CabinetHeader from '../components/Header/CabinetHeader';


const Cabinet = () => {
  return (
    <div className="cabinet">
      <Sidebar />
      <CabinetHeader/>
      <div className="cabinet-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Cabinet;
