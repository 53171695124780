import React from 'react';
import { FooterContainer, LeftText, RightText } from './Footer.styled';

const Footer = () => {
  return (
    <FooterContainer>
      <LeftText>Copyright © 2022 Miraplay</LeftText>
      <RightText>ТОВ Дока Проект ЄДРПОУ 41204288</RightText>
    </FooterContainer>
  );
};

export default Footer;
