import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getLanguage } from '../../Redux/localization/localization-action';
import { setLanguage } from '../../Redux/localization/localization-slice';
import sprite from '../../images/Assets/sprite.svg'

const LocalizationSelect = styled.div`
  display: inline-block;
  position: relative;
  margin-right:20px;
  margin-left: -20px;
  @media (min-width: 768px) and (max-width: 1024px) {
    z-index:3;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 56px;
  height: 34px;
  box-shadow: 0px 20px 40px 0px #3F9C144D;
  background-color: rgba(148, 151, 146, 0.2);
`;

const SelectedText = styled.p`
  margin: 0;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
`;

const LanguageList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

const LanguageItem = styled.li`
display: flex;
align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 56px;
  height: 34px;
  box-shadow: 0px 20px 40px 0px #3F9C144D; 
  background-color: rgba(148, 151, 146, 0.2);
 
`;

const SvgIcon = styled.svg`
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  fill: rgba(234, 234, 234, 0.8);
  margin-left: 5px;
  
`;

const LanguageSelect = () => {
  const dispatch = useDispatch();
  const [open, setopen] = useState(false);
  const languages = ['ua', 'ru', 'en', 'pl'];
  const changeLanguage = (language) => {
    dispatch(setLanguage(language));
    setopen(false); 
  };
  const selectedLanguage = useSelector(getLanguage);

  return (
    <LocalizationSelect>
      <SelectContainer onClick={() => setopen((prev) => !prev)}>
        <SelectedText>{selectedLanguage.toUpperCase()}</SelectedText>
        <SvgIcon>
          <use href={sprite +"#icon-yelp"} />
        </SvgIcon>
      </SelectContainer>
      <LanguageList open={open}>
        {languages.map(
          (lang) =>
            selectedLanguage !== lang && (
              <LanguageItem key={lang} onClick={() => changeLanguage(lang)}>
                {lang.toUpperCase()}
              </LanguageItem>
            )
        )}
      </LanguageList>
    </LocalizationSelect>
  );
};

export default LanguageSelect;
