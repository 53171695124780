import styled, { css } from 'styled-components';
export const Wrapper = styled.div`
 position:absolute;
 top: 100px;
 left:300px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  padding: 1rem;
`;

export const BannerWrapper = styled.div`
  position: relative;
  width: 1030px;
  height: 200px;
  margin: 48px 39px 36px 69px;
  border: 1px 0px 0px 0px;
  background-position: center;
  display: flex;
  background-color:rgba(32, 32, 32, 0.6);
`;

export const BannerImage = styled.img`
  object-fit: cover;
  border-radius: 16px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  right:10%;
  z-index:1;
`;
export const BannerButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 10%;
  left: 40%;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border:none;
  background-color: rgba(32, 32, 32, 1);
  color: rgba(234, 234, 234, 1);
  cursor: pointer;
  @media (max-width: 1200px) {
    z-index:3;
  }
`;

export const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
export const BannerText = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  z-index: 1;
  margin: auto;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

`;

export const Title = styled.h2`
    font-family: Rubik;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 32px;
    margin-left:70px;
    color:rgba(255, 255, 255, 1);
`;

export const BonusWrapper = styled.div`
  position: relative;
  background-color: rgba(32, 32, 32, 1);
  width: 1030px;
  height: 344px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 32px 32px;
  margin-left:70px;

`;

export const BonusTitle = styled.h2`
    font-family: Rubik;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 32px;
    margin-left:32px;
    color:rgba(255, 255, 255, 1);
`;

export const BonusCTA = styled.p`
  position: absolute;
  right: 10%;
  top:30%;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  ${props =>
    css`
      border-radius: 8px 0px 0px 0px;
      border-left: 3px solid ${props.isActive ? 'rgba(63, 156, 20, 1)' : 'rgba(181, 15, 45, 1)'};
      padding-left: 10px;
    `}
  cursor: pointer;
`;

export const BonusInfo = styled.p`
  color: rgba(234, 234, 234, 0.5);
  font-family: Rubik;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  margin-top:32px;
  margin-left: 32px;
`;

export const BonusBalance = styled.h3`
font-family: Rubik;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: right;
color: rgba(234, 234, 234, 0.5);
margin-top:32px;
margin-left: 32px;
`;

export const BonusCount = styled.span`
  margin-bottom: 1rem;
  font-size: 2rem; 
  font-weight: bold;
  text-align: center;
  color: rgba(234, 234, 234, 1);
`;
export const BonusButton = styled.button`
display: flex;
bottom: 22px;
justify-content: center;
align-items: center;
padding: 5px 10px;
height: 50px;
border-radius: 10px;
border:none;
background-color: black;
color: rgba(234, 234, 234, 1);
cursor: pointer;
`;

export const BonusButtonsContainer = styled.div`
display: flex;
flex-direction:raw;
gap: 20px;
margin-top: 32px;
margin-left: 32px;
`;