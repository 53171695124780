import React from "react";
import headerBg from "../../images/header-background-min.png"
import headerHero from "../../images/header-character-full.jpg"
import svg from '../../images/sprite.svg'
import logo from '../../images/Assets/logoNormal.svg';
import { HeaderCabinetWrapper, Logo, HeaderContent, Text, Button, BackgroundImage, HeroImage, SvgIcon } from "./Header.styled";
import LanguageSelect from "../languageSelect/languageSelect";
import { useTranslation } from "../../hooks/useTranslation";
import { lang } from "./lang";

const CabinetHeader = () => {
  const l = useTranslation(lang)

  return (
    <HeaderCabinetWrapper>
      <Logo src={logo} alt="Logo Miraplay" />
      <HeaderContent>
          <BackgroundImage src={headerBg} alt="Header background" />
          <HeroImage src={headerHero} alt="Header game hero" />
          <Text>{l.text}</Text>
          <Button>
            <SvgIcon>
                <use href={`${svg}#icon-start-play`}/>
            </SvgIcon>
            {l.buttonText}
          </Button>
      </HeaderContent>
      <LanguageSelect />
    </HeaderCabinetWrapper>
  );
};

export default CabinetHeader;
