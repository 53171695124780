import React, { useState } from 'react';
import svg from '../../images/sprite.svg'
import BannerBg from '../../images/banner-girl-character-min.jpg'
import { useTranslation } from '../../hooks/useTranslation';
import {
  Wrapper,
  BannerWrapper,
  BannerImage,
  BannerText,
  BannerButton,
  SvgIcon,
  QuestionWrapper,
  QuestionTitle,
  QuestionText,
  QuestionDate,
  Title,
} from './FAQ.styled.js';
import {lang} from './lang';

const FAQ = () => {
  const l = useTranslation(lang)
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const data = [
    {
      id: 1,
      question: 'Які переваги надає хмарний геймінг?',
      answer: 'Хмарний геймінг дозволяє грати у великі ігри на будь-якому пристрої, не потрібно мати потужний комп\'ютер або консоль.',
    },
    {
      id: 2,
      question: 'Які компанії надають послуги хмарного геймінгу?',
      answer: 'Деякі з найвідоміших компаній, що надають послуги хмарного геймінгу, включають Google Stadia, NVIDIA GeForce Now, та Xbox Cloud Gaming.',
    },
    {
      id: 3,
      question: 'Чи потрібно мати швидкий Інтернет для гри в хмарному геймінгу?',
      answer: 'Так, для гри в хмарному геймінгу потрібен стабільний та швидкий Інтернет для забезпечення низької затримки і високої якості стрімінгу гри.',
    },
  ];
  
  return (
    <Wrapper>
     <BannerWrapper>
        <BannerImage src={BannerBg} alt="Banner"/>
        <BannerText>{l.banner}</BannerText>
        <BannerButton>
            <SvgIcon>
                <use href={`${svg}#icon-start-play`}/>
            </SvgIcon>
            {l.findOutMore}
        </BannerButton>
      </BannerWrapper>

      <Title>FAQ</Title>
      
      <QuestionWrapper >
      {data.map((item, index) => (
        <div key={item.id} onClick={() => toggleAnswer(index)}>
         <QuestionDate>Дата: 22.03.2024</QuestionDate>
          <QuestionTitle>{item.question}</QuestionTitle>
          <QuestionText style={{ maxHeight: activeIndex === index ? 100 : 0, padding: activeIndex === index ? 16 : 0 }}>
            {item.answer}
          </QuestionText> 
        </div>   
      ))}
      </QuestionWrapper>
    </Wrapper>
  );
};

export default FAQ;