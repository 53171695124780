import styled from 'styled-components';
export const Wrapper = styled.div`
 position:absolute;
 top: 100px;
 left:300px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  padding: 1rem;
`;

export const BannerWrapper = styled.div`
  position: relative;
  width: 1030px;
  height: 200px;
  margin: 48px 39px 36px 69px;
  border: 1px 0px 0px 0px;
  background-position: center;
  display: flex;
  background-color:rgba(32, 32, 32, 0.6);
`;

export const BannerImage = styled.img`
  object-fit: cover;
  border-radius: 16px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  right:10%;
  z-index:1;
`;
export const BannerButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 10%;
  left: 40%;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border:none;
  background-color: rgba(32, 32, 32, 1);
  color: rgba(234, 234, 234, 1);
  cursor: pointer;
  @media (max-width: 1200px) {
    z-index:3;
  }
`;

export const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
export const BannerText = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  z-index: 1;
  margin: auto;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

`;
export const Title = styled.h2`
    font-family: Rubik;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 32px;
    margin-left:70px;
    color:rgba(255, 255, 255, 1);
`;
export const QuestionDate = styled.p`
  font-size: 12px;
  color: #666;
  margin-bottom: 8px;
`;

export const QuestionWrapper = styled.div`
position: relative;
width: 1030px;
height: 200px;
margin: 48px 39px 36px 69px;
border: 1px 0px 0px 0px;
background-position: center;
display: flex;
flex-direction: column;
background-color:rgba(32, 32, 32, 0.6);
`;

export const QuestionTitle = styled.h4`
  margin-top: 0;
  margin-bottom: 8px;
  color:rgba(255, 255, 255, 1);
  `;

export const QuestionText = styled.p`
  margin: 0;
  color:rgba(255, 255, 255, 1);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s, padding 0.3s;
  padding: 0 16px;
`;
