import styled from 'styled-components';
import { animated } from '@react-spring/web';
import FormBackground from "../../images/Assets/FormBackEnd.png"

export const SectionContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  background: url(${FormBackground}) center/cover no-repeat; 
  overflow-x: clip;
  background-color: rgba(0, 0, 0);
  
`;

export const Money = styled(animated.img)`
  position: absolute;
  z-index: 1;
  left: 36%;
  top:36%;
`;

export const FormCharacter = styled.img`
  width: 350px;
  height: 600px;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  left: 50%;
  transform: translate( -50%);
`;

export const Content = styled.section`
  max-width: 1220px;
  margin: 0 auto;
  height: 820px;
  display: flex;
  justify-content: center;
  
  @media screen and (max-width: 780px){
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  color: #ffffff;
  margin-top: 100px;

  @media (max-width: 1200px) {
  display:none;
  }
`;

export const Title = styled.h3`
font-family: Rubik;
font-size: 40px;
font-weight: 500;
line-height: 44px;
letter-spacing: -0.04em;
text-align: left;
color: #ffffff;
margin-left: 50px;

  @media (max-width: 1200px) {
    display: none;
    
  }
`;

export const BottomText = styled.p`
font-family: Rubik;
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(206, 206, 206,  0.6);
margin-left: 50px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const FormTitle = styled.h3`
  font-family: Rubik;
  font-size: 30px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #ffffff;
  margin: 20px 0 10px 0;

  @media (max-width: 1200px) {
    margin: 50px 6px 24px 14px;
  }
`;

export const FormDescription = styled.p`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(140, 140, 140, 1);
  margin: 0 0 32px;

  @media (min-width: 768px) {
    margin: 0px 0 24px 10px;
  }

  @media (max-width: 1200px) {
    margin: 0 6px 24px 14px;
  }
`;

export const Form = styled.form`
  align-items: center;
`;

export const ShortRow = styled.div`
  display: flex;
  flex-direction: row;
  input {
    width: 42%;
  }
  input:first-child {
    margin-right: 30px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    input {
      width: 90%;
    }
  }
`;

export const LongRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  padding: 20px;
  width: 90%;
  background: rgba(58, 58, 58, 1);
  border: 1px solid #454545;
  color: #fff;
  caret-color: #fff;
  font-weight: 300;
  font-size: 16px;
  margin-top: 14px;
  margin-bottom: 25px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; // Додаємо box-shadow

  &::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
  }

  &:focus {
    border: 1px solid #3f9c14;
    outline: none;
  }

  &.inputErr {
    border-color: rgba(223, 19, 50, 1);
    border-left: 5px solid rgba(223, 19, 50, 1);

    &:focus {
      border: 1px solid #3f9c14;
      outline: none;
    }
  }

  &.inputSuccess {
    border-left: 5px solid #3f9c14; 
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 30px rgba(58, 58, 58, 1) inset; 
    background: rgba(58, 58, 58, 1);
    -webkit-text-fill-color: #fff;!important;
    caret-color: #fff; !important;
  }
`;

export const TextArea = styled.textarea`
  padding: 20px;
  width: 90%;
  background: rgba(58, 58, 58, 1);
  border: 1px solid #454545;
  border-radius: 10px;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  resize: none;
  overflow: hidden;
  margin-top: 14px;
  margin-bottom: 25px;
  transition: border-color 0.3s ease;

  &::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #9c9c9c;
  }

  &:focus {
    border: 1px solid #3f9c14;
    outline: none;
  }
`;

export const Button = styled.button`
  padding: 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: rgba(63, 156, 20, 1);
  border: none;
  transition: background-color 0.3s ease;

  &:not(:disabled) {
    background-color: #3f9c14;
    color: #ffffff;
    margin-right: 25px;
    &:hover {
      background-color: #45a163;
    }
  }

  &:disabled {
    background-color: rgba(63, 156, 20, 0.5);
    color: #ffffff;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    margin-right: 25px;
  }
`;
