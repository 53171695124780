// MainCallToActionSection.js

import React from "react";
import Dudes from "../../images/Assets/dudesgroup.png";
import Mins from "../../images/Assets/minsgroup.png";
import {
  SectionContainer,
  ContentContainer,
  ImageContainer,
  TextContainer,
  DudesImage,
  MinsImage,
  Title,
  Description,
  ActionButton,
} from "./MainCallToActionSection.styled";
import { useTranslation } from "../../hooks/useTranslation";
import { lang } from "./lang";

const MainCallToActionSection = () => {
  const l = useTranslation(lang);

  return (
    <SectionContainer>
      <ContentContainer>
        <ImageContainer>
          <DudesImage src={Dudes} alt="Dudes" />
        </ImageContainer>
        <TextContainer>
          <Title>{l.title}</Title>
          <Description>{l.text}</Description>
          <ActionButton>{l.button}</ActionButton>
        </TextContainer>
        <ImageContainer>
          <MinsImage src={Mins} alt="Mins" />
        </ImageContainer>
      </ContentContainer>
    </SectionContainer>
  );
};

export default MainCallToActionSection;
