export const lang = {
    ua: {
        settingTitle: "Налаштування",
        banner: 
            'Баннер з довгим текстом Дізнатися більше',
        findOutMore: 'Дізнатися більше',
        email: {
            label: "ЗМІНИТИ ЕЛЕКТРОННУ ПОШТУ",
            placeholder: ["Ваша стара пошта", "Ваша нова пошта"],
            button: "Змінити пошту",
            error: "Введіть правильний email",
            success: "Ваш email оновлено",
          },
          password: {
            label: "ЗМІНИТИ ПАРОЛЬ",
            placeholder: ["Ваш старий пароль", "Ваш новий пароль", "Повторіть новий пароль"],
            button: "Змінити пароль",
            error: `Поля "пароль" і "повторіть пароль" повинні бути однаковими`,
            success: "Пароль змінено",
            validationError: "Пароль має містити щонайменше одну велику, маленьку літеру англійської розкладки та одну цифру, від 8 символів"
          },
          nickname: {
            label: "ПРИДУМАЙТЕ NICKNAME",
            placeholder: ["Ваш NICKNAME"],
            button: "Зберегти NICKNAME"
          },
          checkResetPasswordMessage: "Ви впевнені що хочете скинути пароль?",
          surname: {
            label: "Прізвище",
            placeholder: "Введіть ваше прізвище",
            validationError: "Це поле обов'язкове"
          },
          name: {
            label: "Ім'я",
            placeholder: "Введіть ваше ім'я",
            validationError: "Це поле обов'язкове"
          },
          middleName: {
            label: "По батькові",
            placeholder: "Введіть ваше по батькові"
          },
          nickName: {
            label: "Нікнейм",
            placeholder: "Введіть ваш нікнейм",
            validationError: "Це поле обов'язкове"
          },
          email: {
            label: "Email",
            placeholder: "Введіть ваш email",
            validationError: "Введіть правильний email"
          },
          phone: {
            label: "Телефон",
            placeholder: "Введіть ваш телефон"
          },
          birthDate: {
            label: "Дата народження",
            placeholder: "Введіть вашу дату народження"
          },
          telegram: {
            label: "Telegram",
            placeholder: "Введіть ваш Telegram"
          },
          whatsApp: {
            label: "WhatsApp",
            placeholder: "Введіть ваш WhatsApp"},

            identificationCode: {
              label: "Ідентифікаційний код",
              placeholder: "Введіть ваш ідентифікаційний код",
              validationError: "Це поле обов'язкове"
            },
            passportSeries: {
              label: "Серія паспорта",
              placeholder: "Введіть серію вашого паспорта",
              validationError: "Це поле обов'язкове"
            },
            passportRegistration: {
              label: "Реєстрація паспорта",
              placeholder: "Введіть вашу реєстрацію паспорта",
              validationError: "Це поле обов'язкове"
            },
            passportIssued: {
              label: "Ким виданий паспорт",
              placeholder: "Введіть ким виданий ваш паспорт",
              validationError: "Це поле обов'язкове"
            },
            IBAN: {
              label: "IBAN",
              placeholder: "Введіть ваш IBAN",
              validationError: "Це поле обов'язкове"
            },
            FOPName: {
              label: "Назва ФОП",
              placeholder: "Введіть назву вашого ФОП",
              validationError: "Це поле обов'язкове"
            },
            TOVName: {
              label: "Назва ТОВ",
              placeholder: "Введіть назву вашого ТОВ",
              validationError: "Це поле обов'язкове"
            },
            EDRPUO: {
              label: "ЄДРПУО",
              placeholder: "Введіть ваш ЄДРПУО",
              validationError: "Це поле обов'язкове"
            },
            privatePerson:'приватне лице',
            FOP:'FOP лице',
            TOV:'TOV лице',

        
       },
    //---------------ru-----------
    ru: {
        settingTitle: "Личные данные",
        banner: [
            'Баннер',
            'з',
            'довгим',
            'текстом',
            'Дізнатися',
            'більше',
          ],
        email: {
            label: "ИЗМЕНИТЬ ЭЛЕКТРОННУЮ ПОЧТУ",
            placeholder: ["Ваша старая почта", "Ваша новая почта"],
            button: "Изменить почту",
            error: "Введите правильный текущий email",
            success: "Ваш email был успешно обновлен",
        },
        password: {
            label: "ИЗМЕНИТЬ ТЕКУЩИЙ ПАРОЛЬ",
            placeholder: ["Ваш старый пароль", "Ваш новый пароль", "Повторите новый пароль"],
            button: "Изменить пароль",
            error: `В полях "пароль" и "повторить пароль" должны быть одинаковые значения`,
            success: "Пароль изменен",
            validationError: "Пароль должен содержать по меньшей мере одну прописную, маленькую букву английской раскладки и одну цифру от 8 символов"
        },
        nickname: {label: "ПРИДУМАЙТЕ NICKNAME", placeholder: ["Ваш NICKNAME",], button: "Сохранить NICKNAME"},
        checkResetPasswordMessage: "Вы уверены, что хотите сбросить пароль?"
    },
    //---------------en-----------
    en: {
        settingTitle: "Personal information",
        banner: [
            'Баннер',
            'з',
            'довгим',
            'текстом',
            'Дізнатися',
            'більше',
          ],
        email: {
            label: "CHANGE EMAIL",
            placeholder: ["Your old email", "Your new email"],
            button: "Change email",
            error: "Enter the correct current email",
            success: "Your email has been successfully updated",
        },
        password: {
            label: "CHANGE CURRENT PASSWORD",
            placeholder: ["Your old password", "Your new password", "Retype new password"],
            button: "Change password",
            error: `The "password" and "repeat password" fields must have the same values`,
            success: "Password changed",
            validationError: "The password must contain at least one uppercase, lowercase letter of the English alphabet and one number, from 8 characters",
        },
        nickname: {label: "CREATE A NICKNAME", placeholder: ["Your NICKNAME",], button: "Save NICKNAME"},
        checkResetPasswordMessage: "Are you sure you want to reset your password?"
    },
    //---------------pl-----------
    pl: {
        settingTitle: "Dane osobowe",
        banner: [
            'Баннер',
            'з',
            'довгим',
            'текстом',
            'Дізнатися',
            'більше',
          ],
        email: {
            label: "ZMIEŃ ADRES EMAIL",
            placeholder: ["Twój stary email", "Twój nowy email"],
            button: "Zmień email",
            error: "Wprowadź poprawny aktualny adres email",
            success: "Twój adres email został pomyślnie zaktualizowany",
        },
        password: {
            label: "ZMIEŃ HASŁO",
            placeholder: ["Twoje stare hasło", "Twoje nowe hasło", "Powtórz nowe hasło"],
            button: "Zmień hasło",
            error: `Pola "hasło" i "powtórz hasło" muszą mieć takie same wartości`,
            success: "Hasło zostało zmienione",
            validationError: "Hasło musi zawierać co najmniej jedną wielką literę, małą literę alfabetu angielskiego i jedną cyfrę, od 8 znaków",
        },
        nickname: {label: "USTAW NICKNAME", placeholder: ["Twój NICKNAME",], button: "Zapisz NICKNAME"},
        checkResetPasswordMessage: "Czy na pewno chcesz zresetować swoje hasło?"
    }
};
