import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BackdropHidden, Form, Input, Label, SubTitle, SubmitBtn, Title, CloseBtn, Content } from './AuthModal.styled'; // Підключаємо стилі з AuthModal.styled
import { useNavigate } from 'react-router-dom';

const AuthModal = ({ onClose }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [isAdmin, setIsAdmin] = useState(false); // Стан, що вказує, чи відбувається авторизація як адміністратор
    const navigate = useNavigate(); // Функція для перенаправлення на інші роути

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
        
        if (isAdmin) {
            navigate('/admin');
        } else {
            navigate('/cabinet');
        }
        onClose();
    };

    const handleAdminCheckboxChange = () => {
        setIsAdmin(!isAdmin);
    };

    return (
        <BackdropHidden>
            <Content>
            <motion.div
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        onClose(); 
                    }
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Form onSubmit={handleSubmit}>
                    <Title>Авторизація</Title>
                    <SubTitle>Введіть ваші дані для входу</SubTitle>
                    <Label>Email:
                        <Input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Ваш email"
                        />
                    </Label>
                    <Label>Пароль:
                        <Input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Ваш пароль"
                        />
                    </Label>
                    <Label>
                        <input
                            type="checkbox"
                            checked={isAdmin}
                            onChange={handleAdminCheckboxChange}
                        />
                        Адмін
                    </Label>
                    <SubmitBtn type="submit">Увійти</SubmitBtn>
                </Form>
                <CloseBtn onClick={onClose}>Закрити</CloseBtn>
            </motion.div>
            </Content>
        </BackdropHidden>
    );
};

export default AuthModal;
