import React from 'react';
import svg from '../../images/sprite.svg'
import BannerBg from '../../images/banner-background-min.jpg'
import { BannerImage, BannerText, BannerWrapper, BannerButton, FeatureImage, FeatureItem, FeatureText, FeatureTitle, Features, LanguageSelect, Title, Wrapper, SvgIcon, FeatureButton, FeatureContent, FeatureNumber, FeatureAccent, FeatureTextWrapper } from './profile.styled';
import { useTranslation } from '../../hooks/useTranslation';
import { lang } from './lang';

const Profile = () => {

  const l = useTranslation(lang)
  const newMonthDate= 11.02;
  const isActive= false;
  const userBalance = 99;
  const userRefferals = 10000;
  const balanceLack = 1;

  const data = [
    {
      id: 1,
      iconTitle: isActive ? `${svg}#icon-green-accept` : `${svg}#icon-red-warning`, 
      iconButton: `${svg}#icon-green-dollar`,
      title: isActive ? l.activeAccount : l.inactiveAccount,
      numberData:  `${userBalance} ${l.userBalance}`,
      balanceCheck: `${userBalance} / 100 ${l.topUpBalance[1]}`,
      description: isActive ? `${l.topUpNext} ${newMonthDate}`  : `${l.topUpBalance[0]} ${balanceLack} ${l.topUpBalance[1]}`,
      button: l.topUp,
    },
    {
      id: 2,
      iconButton: isActive ? `${svg}#icon-green-accept` : `${svg}#icon-red-warning`,
      title: l.balance,
      numberData:  `${userRefferals} ${l.mrcBalance}`,
      description: isActive ? `${l.activeBonus}` : `${l.inactiveBonus}`,
      button: isActive ? l.earning : l.notEarning,
    },
    {
      id: 3,
      iconSource: `${svg}#icon-start-play`,
      iconButton: `${svg}#icon-start-play`,
      title: l.referrals,
      numberData: `${userRefferals} ${l.invitedUsers}`,
      description: `${l.activeUsers[0]} `,
      descriptionAccent:`${userRefferals} ${l.activeUsers[1]}`,
      button: l.findOutMore,
    },
  ];

  return (
    <Wrapper>
     <BannerWrapper>
        <BannerImage src={BannerBg} alt="Banner"/>
        <BannerText>{l.banner}</BannerText>
        <BannerButton>
            <SvgIcon>
                <use href={`${svg}#icon-start-play`}/>
            </SvgIcon>
            {l.findOutMore}
          </BannerButton>
      </BannerWrapper>
    <Title>{l.title}</Title>
    <Features>
        {data.map((item, index) => (
          <FeatureItem key={item.id}>
            <FeatureTitle>   
              <SvgIcon>
                <use href={`${item.iconTitle}`} />
              </SvgIcon>
              {item.title}
            </FeatureTitle>
            
            <FeatureText>
              <FeatureNumber>{item.numberData.split(' ')[0]}</FeatureNumber>
              {item.numberData.slice(item.numberData.indexOf(' ') + 1)}
            </FeatureText>
            
            <FeatureContent first={item.id === 1} active ={isActive}>
              {item.balanceCheck}
            </FeatureContent>
           
           <FeatureTextWrapper second={item.id === 2} active ={isActive}>
            <FeatureText >
              {item.description}  
            <FeatureAccent>{item.descriptionAccent}</FeatureAccent>
            </FeatureText>
          </FeatureTextWrapper>  

              <FeatureButton>
                <SvgIcon>
                  <use href={`${item.iconButton}`} />
                </SvgIcon>
                {item.button}
              </FeatureButton>
          </FeatureItem>
        ))}
      </Features>
  </Wrapper>
  );
};

export default Profile;
