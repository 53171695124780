 export const lang = {
    ua: {
      banner: 'Банер з довгим текстом',
      findOutMore: 'Дізнатися більше',
      bonusBalance: 'Мій баланс бонусів',
      bonus: 'Бонуси',
      bonusCount: 'бонусів',
      buttons: ['Подати заявку на виведення','Перевести на рахунок Miraplay','Вивести на криптокошелек'],
      bonusInfo: 'Бонуси можна отримати в кінці місяця. Бонуси можна отримати в кінці місяця',
      activeBonus: ['Ваші бонуси активні,Ви можете їх вивести',' на карту або перевести на рахунок Miraplay'],
      inactiveBonus: ['Щоб витрачати бонуси,','поповніть рахунок.'],
    },
    en: {
      banner: 'Banner with a long text',
      findOutMore: 'Find out more',
      bonusBalance: 'My bonus balance',
      bonus: 'Bonuses',
      bonusCount: 'bonuses',
      buttons: ['Apply for withdrawal','Transfer to Miraplay account','Withdraw to crypto wallet'],
      bonusInfo: 'Bonuses can be obtained at the end of the month. Bonuses can be obtained at the end of the month',
      activeBonus: 'Your bonuses are active',
      activeBonusInfo: 'You can withdraw them to your card or transfer them to your Miraplay account',
    },
    pl: {
      banner: 'Baner z długim tekstem',
      findOutMore: 'Dowiedz się więcej',
      bonusBalance: 'Mój bilans bonusów',
      bonus: 'Bonusy',
      bonusCount: ' bonusów',
      buttons: ['Złóż wniosek o wypłatę','Przelew na konto Miraplay','Wypłata na portfel kryptowalutowy'],
      bonusInfo: 'Bonusy można otrzymać na koniec miesiąca. Bonusy można otrzymać na koniec miesiąca',
      activeBonus: 'Twoje bonusy są aktywne',
      activeBonusInfo: 'Możesz je wypłacić na kartę lub przelać na konto Miraplay',
    },
    ru: {
        banner: 'Баннер с длииииииии текстом',
        findOutMore: 'Узнать подробнее',
        bonusBalance: 'Мой баланс бонусов',
        bonus: 'Бонусы',
        bonusCount: 'бонусов',
        buttons: ['Подать заявку на вывод','Перевести на счёт Miraplay','Вывести на криптокошелек'],
        bonusInfo: 'О Бонусы можно получить в конце месяца. Бонусы можно получить в конце месяца',
        activeBonus: 'Ваши бонусы активны',
        activeBonusInfo: 'Можете их вывести на карту или перевести на Мігаріву счёт',
    },
  };
  