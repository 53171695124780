 export const lang = {
    ua: {
      banner: 'Банер з довгим текстом',
      findOutMore: 'Дізнатися більше',
    },
    en: {
      banner: 'Banner with a long text',
      findOutMore: 'Find out more',
    },
    pl: {
      banner: 'Baner z długim tekstem',
      findOutMore: 'Dowiedz się więcej',
    },
    ru: {
        banner: 'Баннер с длииииииии текстом',
        findOutMore: 'Узнать подробнее',
    },
  };
  