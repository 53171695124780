export const lang = {
  ua: {
    myProfile: ['Мій профіль'],
    myBonus: ['Мої бонуси'],
    myReferrals: ['Мої реферали'],
    referralLink: ['Реферальна посилання'],
    faq: ['FAQ'],
    faqSetting: ['Налаштування FAQ'],
    users: ['Користувачі'],
    statistic: ['Статистика'],
    mainChange: ['Основні налаштування'],
    feedback: ['Зворотній зв\'язок'],
    menuTitle: ['Меню'],
    settingBtn: ['Налаштування'],
    logOutBtn: ['Вихід'],
  },
  en: {
    myProfile: ['My Profile'],
    myBonus: ['My Bonuses'],
    myReferrals: ['My Referrals'],
    referralLink: ['Referral Link'],
    faq: ['FAQ'],
    faqSetting: ['FAQ Settings'],
    users: ['Users'],
    statistic: ['Statistics'],
    mainChange: ['Main Settings'],
    feedback: ['Feedback'],
    menuTitle: ['Menu'],
    settingBtn: ['Settings'],
    logOutBtn: ['Log Out'],
  },
  pl: {
    myProfile: ['Mój profil'],
    myBonus: ['Moje bonusy'],
    myReferrals: ['Moje poleconych'],
    referralLink: ['Link polecający'],
    faq: ['FAQ'],
    faqSetting: ['Ustawienia FAQ'],
    users: ['Użytkownicy'],
    statistic: ['Statystyki'],
    mainChange: ['Główne ustawienia'],
    feedback: ['Opinie'],
    menuTitle: ['Menu'],
    settingBtn: ['Ustawienia'],
    logOutBtn: ['Wyloguj'],
  },
  ru: {
    myProfile: ['Мой профиль'],
    myBonus: ['Мои бонусы'],
    myReferrals: ['Мои рефералы'],
    referralLink: ['Реферальная ссылка'],
    faq: ['FAQ'],
    faqSetting: ['Настройки FAQ'],
    users: ['Пользователи'],
    statistic: ['Статистика'],
    mainChange: ['Основные настройки'],
    feedback: ['Обратная связь'],
    menuTitle: ['Меню'],
    settingBtn: ['Настройки'],
    logOutBtn: ['Выход'],
  },
};
