import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { lang } from './lang';
import FormCharacterImage from "../../images/Assets/formSectionCharacter.png"; 
// import Money from "../Assets/moneyLeftPart.png"
// import MoneyRight from "../Assets/moneyLeftPart.png"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Notiflix from 'notiflix';
import { SectionContainer, 
  Content, 
  TextContainer, 
  Title, 
  BottomText, 
  FormContainer, 
  Form, 
  ShortRow, 
  LongRow, 
  Input, 
  TextArea, 
  Button,
  FormCharacter,
  } from './MainFormSection.styled';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  city: Yup.string().required('City is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  message: Yup.string(),
});


const MainFormSection = () => {
  const l = useTranslation(lang);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      city: '',
      phone: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch('https://api.miraplay.cloud/computer_landlords/email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result);
          Notiflix.Report.success(
            l.notificationTitle,
            l.notificationMessage,
            'Ok');
          formik.resetForm();
        } else {
          const errorResult = await response.json();
          console.error(errorResult);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }},
  });


  return (
    <SectionContainer  id='partnerform'>
    <Content>
    <FormCharacter src={FormCharacterImage} alt="GTA6 character" /> 
      <TextContainer>
        <Title>
          {l.title[0]} <br /> 
          {l.title[1]} <br /> 
          {l.title[2]}
        </Title>
        <BottomText>
          {l.text[0]}<br />
          {l.text[1]}<br />
        </BottomText>
      </TextContainer>
      
        <FormContainer>  
     <Form onSubmit={formik.handleSubmit}>
      <ShortRow>
      <Input
      type="text"
      name="name"
      value={formik.values.name}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.name}
      className={formik.touched.name ? (formik.errors.name ? 'inputErr' : 'inputSuccess') : ''}
      />
      <Input
      type="text"
      name="city"
      value={formik.values.city}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.city}
      className={formik.touched.city ? (formik.errors.city ? 'inputErr' : 'inputSuccess') : ''}
      />
    </ShortRow>
    <LongRow>
    <Input
      type="email"
      name="email"
      value={formik.values.email}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.email}
      className={formik.touched.email ? (formik.errors.email ? 'inputErr' : 'inputSuccess') : ''}
    />
    <Input
      type="tel"
      name="phone"
      value={formik.values.phone}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.phone}
      className={formik.touched.phone ? (formik.errors.phone ? 'inputErr' : 'inputSuccess') : ''}
    />
    <TextArea
      name="message"
      value={formik.values.message}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.message}
      className={formik.touched.message ? (formik.errors.message ? 'inputErr' : 'inputSuccess') : ''}
    />
    </LongRow>
    <Button type="submit" disabled={!formik.isValid}>
    {l.Btn}
    </Button>
  </Form>
        </FormContainer>
      </Content>
    </SectionContainer>
  );
};

export default MainFormSection;
