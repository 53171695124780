import styled from 'styled-components';

export const SidebarContainer = styled.div`
  position:relative;
  background: rgba(32, 32, 32, 1);
  color: rgba(234, 234, 234, 1);
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1rem;
  z-index:2;
  justify-content: space-between;
`;

export const SidebarTitle = styled.h1`
  font-size: 18px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
`;

export const SidebarLinks = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const SidebarLink = styled.li`
  margin-bottom: 1rem;
  display:flex;
 

  a {
    text-decoration: none;
    color: rgba(234, 234, 234, 1);
    display: flex;
    align-items: center;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    &.active {
      color: rgba(257, 257, 257, 1);
    }
  }
`;

 export const UserActionsWrapper = styled.div`
  display: flex;
  flex-direction: raw;
  align-items: center;
  margin-bottom: 100px;
 `;

 export const UserActionsContent = styled.div`
 display: flex;
 flex-direction: column;
`;

 export const UserLogin = styled.h2`
 font-family: Rubik;
 font-size: 14px;
 font-weight: 500;
 line-height: 20px;
 text-align: left;
 margin: auto 5px;
 `;

 export const UserAvatar = styled.img`
  width: 36px;
  height: 36px; 
   border-radius: 50%;
  margin-right: 10px;
  object-fit: cover; 
  object-position: center;
 `;

 export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: raw;
 `;

export const LogoutButton = styled.button`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  background: rgba(32, 32, 32, 1);
  color: rgba(234, 234, 234, 0.25);
  padding-left: 5px;
  border: none;
  border-left: 2px solid rgba(37, 37, 37, 1);
  cursor: pointer;
`;
export const SettingButton = styled.button`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  margin-right: 5px;
  height: 20px;
  border: none;
  background: rgba(32, 32, 32, 1);
  cursor: pointer;
  a {
    text-decoration: none;
    color: rgba(234, 234, 234, 0.75);

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    &.active {
      color: rgba(257, 257, 257, 1);
    }
`;

export const Icon = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  fill: rgba(234, 234, 234, 0.3);


  ${SidebarLink}:hover & {
    fill: rgba(255, 255, 255, 1);
  }
`;