export const lang = {
  ua: {
    banner: 'Банер з довгим текстом',
    findOutMore: 'Дізнатися більше',
    title: 'Мої реферальні посилання',
    createLink: 'Створити посилання',
    copied: 'Скопійовано!',
    delete: 'Видалити',
    myLinks: 'Мої посилання',
    nameLink: 'Найменування посилання',
    shareLink: 'Поділитись посиланням з другом, по якому він повинен пройти реєстрацію.',
  },
  ru: {
    banner: 'Баннер с длинным текстом',
    findOutMore: 'Узнать больше',
    title: 'Мои реферальные ссылки',
    createLink: 'Создать ссылку',
    copied: 'Скопировано!',
    delete: 'Удалить',
    myLinks: 'Мои ссылки',
    nameLink: 'Название ссылки',
    shareLink: 'Поделиться ссылкой с другом, по которой он должен пройти регистрацию.',
  },
  en: {
    banner: 'Banner with a long text',
    findOutMore: 'Find out more',
    title: 'My referral links',
    createLink: 'Create link',
    copied: 'Copied!',
    delete: 'Delete',
    myLinks: 'My links',
    nameLink: 'Link name',
    shareLink: 'Share the link with a friend, which he must follow to register.',
  },
  pl: {
    banner: 'Baner z długim tekstem',
    findOutMore: 'Dowiedz się więcej',
    title: 'Moje linki polecające',
    createLink: 'Stwórz link',
    copied: 'Skopiowano!',
    delete: 'Usuń',
    myLinks: 'Moje linki',
    nameLink: 'Nazwa linku',
    shareLink: 'Udostępnij link znajomemu, który musi go użyć do rejestracji.',
  },
};
