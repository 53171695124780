import React from 'react';
import svg from '../../images/sprite.svg'
import BannerBg from '../../images/banner-girl-character-min.jpg'
import { useTranslation } from '../../hooks/useTranslation';
import {
  Wrapper,
  BannerWrapper,
  BannerImage,
  BannerText,
  BannerButton,
  SvgIcon,
} from './Referrals.styled.js';
import {lang} from './lang';

const Referrals = () => {
  const l = useTranslation(lang)

  
  return (
    <Wrapper>
     <BannerWrapper>
        <BannerImage src={BannerBg} alt="Banner"/>
        <BannerText>{l.banner}</BannerText>
        <BannerButton>
            <SvgIcon>
                <use href={`${svg}#icon-start-play`}/>
            </SvgIcon>
            {l.findOutMore}
        </BannerButton>
      </BannerWrapper>
    </Wrapper>
  );
};

export default Referrals;