import React from "react";
import LevelSectionDude from "../../images/Assets/levelsectionman.png"
// import Tablet from "../Assets/Group 483031.png"
import { lang } from "./lang";
import {useTranslation} from "../../hooks/useTranslation";
import { ContentContainer, MainImage, SectionContainer, SlideText, SlideTitle, TextOverlay } from "./MainLevelSection.styled";

 const MainLevelSection = () => {

  const l = useTranslation(lang);

  const animation = {
    
    hidden: {
        y: -50,
        opacity: 0,
    },
    visible: (custom = 0) => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.15, duration: 1},

    }),
};

  return (
    <SectionContainer>
  
      <ContentContainer>
     
      <TextOverlay>  
        
          <SlideTitle variants={animation}
                      initial="hidden"
                      animate="visible"
                      custom={3}>
            {l.title}
          </SlideTitle>

          <SlideText variants={animation}
                      initial="hidden"
                      animate="visible"
                      custom={4}>
          {l.text[0].toUpperCase()} <br/>
          {l.text[1].toUpperCase()}<br/>
          {l.text[2].toUpperCase()}<br/>
          {l.text[3].toUpperCase()}<br/>
          {l.text[4].toUpperCase()}<br/>
          </SlideText>
      </TextOverlay>  
      
      <MainImage src={LevelSectionDude} alt="GTA character with gun" />
      {/* <TabletImage src={Tablet} alt="GTA character with gun" /> */}
     </ContentContainer>
    </SectionContainer>
  );
};

export default MainLevelSection;