import React from 'react';
import {lang} from './lang';
import svg from '../../images/sprite.svg'
import userAvatar from '../../images/header-character-full.jpg'
import { useTranslation } from '../../hooks/useTranslation';
import { Link } from 'react-router-dom';
import { SidebarContainer, SidebarTitle, SidebarLinks, SidebarLink, LogoutButton, Icon, UserAvatar, ActionButtonsWrapper, SettingButton, UserActionsWrapper, UserLogin, UserActionsContent } from './SideBar.styled';


const Sidebar = ({ isAdmin }) => {
  const l = useTranslation(lang);

  const someUserLogin = 'loginLoginovich'

  const getIcon = (iconName) => (
    <svg className="icon" aria-hidden="true">
      <use href={`${svg}#${iconName}`} />
    </svg>
  );

  const sidebar = isAdmin
    ? [
        {
          title: l.menuTitle,
          icon: getIcon('icon-home-outline'),
          links: [
            { title: l.myProfile, link: '/admin/profile', icon: getIcon('icon-solar_user-bold') },
            { title: l.myBonus, link: '/admin/bonus', icon: getIcon('icon-bonus') },
            { title: l.myReferrals, link: '/admin/referrals', icon: getIcon('icon-noun-users') },
            { title: l.referralLink, link: '/admin/link', icon: getIcon('icon-link-fill') },
            { title: l.faqSetting, link: '/admin/faq', icon: getIcon('icon-star') },
            { title: l.users, link: '/admin/users', icon: getIcon('users') },
            { title: l.statistic, link: '/admin/statistic', icon: getIcon('users') },
            { title: l.mainChange, link: '/admin/main-change', icon: getIcon('main-change') },
            { title: l.feedback, link: '/admin/feedback', icon: getIcon('icon-statistic') },
          ],
        },
      ]
    : [
        {
          title: l.menuTitle,
          icon: getIcon('icon-home-outline'),
          links: [
            { title: l.myProfile, link: 'profile', icon: getIcon('icon-solar_user-bold') },
            { title: l.myBonus, link: 'bonus', icon: getIcon('icon-bonus') },
            { title: l.myReferrals, link: 'referrals', icon: getIcon('icon-noun-users') },
            { title: l.referralLink, link: 'link', icon: getIcon('icon-link-fill') },
            { title: l.faq, link: 'faq', icon: getIcon('icon-star') },
          ],
        },
      ];

  return (
    <SidebarContainer>
      <SidebarTitle>
        <Icon>{sidebar[0].icon}</Icon>
        {sidebar[0].title}
      </SidebarTitle>
      <SidebarLinks>
        {sidebar.map((item, i) => (
          <React.Fragment key={i}>
            {item.links.slice(0).map((link, j) => (
              <SidebarLink key={j}>
                <Icon>{link.icon}</Icon>
                <Link to={link.link}>{link.title}</Link>
              </SidebarLink>
            ))}
          </React.Fragment>
        ))}
      </SidebarLinks>

      <UserActionsWrapper>
        <UserAvatar src={userAvatar} alt='User profile Avatar'/> 
       <UserActionsContent>
         <UserLogin>{someUserLogin}</UserLogin>
        <ActionButtonsWrapper>
          <SettingButton>
          <Link to="setting">{l.settingBtn}</Link> 
          </SettingButton>
          <LogoutButton>{l.logOutBtn}</LogoutButton>
        </ActionButtonsWrapper>
       </UserActionsContent>
      </UserActionsWrapper>
      
    </SidebarContainer>
  );
};

export default Sidebar;