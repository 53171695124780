import React, { useEffect } from 'react';
import { Routes, Switch, Route, Redirect} from 'react-router-dom';
import MainPage from './pages/mainPage';
import Bonus from './components/Bonus/Bonus';
import Referrals from './components/Referrals/Referrals';
import Link from './components/ReferralLink/ReferalLink';
import Feedback from './components/Feedback/Feedback';
import FAQ from './components/FAQ/FAQ';
import Users from './components/Users/Users';
import SettingFAQ from './components/SettingFAQ/SettingFAQ';
import UserSetting from './components/UserSetting/UserSetting';
import Profile from './components/Profile/Profile';
import Cabinet from './pages/cabinetPage';
import Admin from './pages/adminPage';

const App = () => {
  // const { user } = useAuth();
  // const location = useLocation();
  // const history = useHistory();

  // useEffect(() => {
  //   if (location.pathname.startsWith('/admin') && (!user || user.role !== 'admin')) {
  //     history.push('/');
  //   }
  // }, [location.pathname, user, history]);

    return (
      <Routes>
        <Route path="/" element={<MainPage />} />

        <Route path="/cabinet" element={<Cabinet />}>
          <Route index element={<Profile />} />
          <Route path="profile" element={<Profile />} />
          <Route path="bonus" element={<Bonus />} />
          <Route path="referrals" element={<Referrals />} />
          <Route path="link" element={<Link />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="setting" element={<UserSetting />} />
        </Route>

        <Route path="/admin" element={<Admin />}>
          <Route index element={<Profile />} />
          <Route path="profile" element={<Profile />} />
          <Route path="bonus" element={<Bonus />} />
          <Route path="referrals" element={<Referrals />} />
          <Route path="link" element={<Link />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="setting/faq" element={<SettingFAQ/>} />
          <Route path="statistic" element={<Referrals />} />
          <Route path="users" element={<Users />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="main-change" element={<UserSetting />} />
        </Route>
      </Routes>
    );
  };

export default App;
