import React from 'react';
import svg from '../../images/sprite.svg'
import BannerBg from '../../images/banner-girl-character-min.jpg'
import { useTranslation } from '../../hooks/useTranslation';
import {
  StyledBonusBanner,
  Wrapper,
  BannerWrapper,
  BannerImage,
  BannerText,
  BannerButton,
  SvgIcon,
  BonusTitle,
  BonusCTA,
  BonusBalance,
  BonusButtonsContainer,
  BonusButton,
  BonusWrapper,
  BonusInfo,
  Title,
  BonusCount,
} from './Bonus.styled';
import {lang} from './lang';

const Bonus = () => {
  const l = useTranslation(lang)
  const bonusCount = 10000;
  const isActive= true;

  const buttons = [
    {text: `${l.buttons[0]}`, icon: `${svg}#icon-green-accept`,},
    {text: `${l.buttons[1]}`, icon: `${svg}#icon-green-accept`,},
    {text: `${l.buttons[2]}`,icon: `${svg}#icon-green-accept`,},
  ];
  
  return (
    <Wrapper>
     <BannerWrapper>
        <BannerImage src={BannerBg} alt="Banner"/>
        <BannerText>{l.banner}</BannerText>
        <BannerButton>
            <SvgIcon>
                <use href={`${svg}#icon-start-play`}/>
            </SvgIcon>
            {l.findOutMore}
        </BannerButton>
      </BannerWrapper>
    
      <Title>{l.bonusBalance}</Title>
        
        <BonusWrapper>
        <BonusTitle>{l.bonus}</BonusTitle>
        <BonusBalance>
          <BonusCount>{bonusCount}</BonusCount> {l.bonusCount}
        </BonusBalance>
        <BonusCTA isActive={isActive}>
          {isActive ? l.activeBonus[0] : l.inactiveBonus[0]}
          <br />
          {isActive ? l.activeBonus[1] : l.inactiveBonus[1]}
        </BonusCTA>       
        <BonusButtonsContainer>
            {buttons.map((button, index) => (
            <BonusButton key={index}>
              <SvgIcon>
                  <use href={`${button.icon}`} />
                </SvgIcon>
                {button.text}
              </BonusButton>
          ))}
        </BonusButtonsContainer>
        <BonusInfo>{l.bonusInfo}</BonusInfo>
    </BonusWrapper>
    </Wrapper>
  );
};

export default Bonus;