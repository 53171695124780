import React, { useState } from 'react';
import svg from '../../images/sprite.svg'
import BannerBg from '../../images/banner-girl-character-min.jpg'
import { useTranslation } from '../../hooks/useTranslation';
import {
  Wrapper,
  BannerWrapper,
  BannerImage,
  BannerText,
  BannerButton,
  SvgIcon,
  Title,
  LinkWrapper,
  LinkTitle,
  LinkInfo,
  LinkButton,
  LinkInput,
  QrWrapper,
  QRCodeContainer,
  QRCodeTitle,
  QRCodeLink,
} from './ReferralLink.styled';
import {lang} from './lang';
import QRCode from 'qrcode.react';

const ReferralLink = () => {
  const l = useTranslation(lang);
  const someLink = 'miraplay.cloud/my-account?token=dmFuaFpbGdsdbмddgdfgfdgfdgdgdgfhgffgfgdfgfdgdgfdgdgddggdfcdhsb20=';

  const [showQRCode, setShowQRCode] = useState(false);

  return (
    <Wrapper>
      <BannerWrapper>
        <BannerImage src={BannerBg} alt="Banner" />
        <BannerText>{l.banner}</BannerText>
        <BannerButton>
          <SvgIcon>
            <use href={`${svg}#icon-start-play`} />
          </SvgIcon>
          {l.findOutMore}
        </BannerButton>
      </BannerWrapper>

      <Title>{l.title}</Title>

      <LinkWrapper>
        <LinkTitle>{l.createLink}</LinkTitle>
        <LinkInfo>{l.shareLink}</LinkInfo>
        <LinkInput value={someLink} readOnly />
        <LinkButton onClick={() => setShowQRCode(true)}>{l.createLink}</LinkButton>
      </LinkWrapper>

      {showQRCode && (
      <QrWrapper>
        <LinkTitle>{l.myLinks}</LinkTitle>
        <QRCodeContainer>
          <QRCode value={someLink} />
          <div>
            <QRCodeTitle>{l.nameLink}</QRCodeTitle>
            <QRCodeLink>{someLink}</QRCodeLink>
          </div>
        </QRCodeContainer>
      </QrWrapper>
        )}
    </Wrapper>
  );
};

export default ReferralLink;