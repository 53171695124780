import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from '../../hooks/useTranslation';
import { lang } from './lang';
import svg from '../../images/sprite.svg'
import BannerBg from '../../images/banner-background-min.jpg'
import { BannerButton, BannerImage, BannerText, BannerWrapper, ButtonsWrapper, FopInputs, InputDataWrapper, InputField, InputLabel, PersonalDataWrapper, PrivatePersonWrapper, SvgIcon, TOVInputs, Title, Wrapper } from './UserSetting.styled';

const UserSetting = () => {
  const l = useTranslation(lang);
  const username = `someone`;
  const email = `somesing`;
  const [fieldValue, setFieldValue] = useState()

  const validationSchema = {
    userData: Yup.object().shape({
      surname: Yup.string().required(l.surname.validationError),
      name: Yup.string().required(l.name.validationError),
      middleName: Yup.string(),
      nickName: Yup.string().required(l.nickName.validationError),
      email: Yup.string().email(l.email.validationError).required(l.email.validationError),
      phone: Yup.string(),
      birthDate: Yup.string(),
      telegram: Yup.string(),
      whatsApp: Yup.string(),
    }),
    password: Yup.object().shape({
      oldPassword: Yup.string().required(l.password.validationError),
      password: Yup.string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_]{8,}/, l.password.validationError).required(l.password.validationError),
      passwordRepeat: Yup.string().oneOf([Yup.ref('password'), null], l.password.repeatError).required(l.password.validationError)
    }),
    privatePersonData: Yup.object().shape({
      identificationCode: Yup.string().required(l.identificationCode.validationError),
      passportSeries: Yup.string().required(l.passportSeries.validationError),
      passportRegistration: Yup.string().required(l.passportRegistration.validationError),
      passportIssued: Yup.string().required(l.passportIssued.validationError),
      IBAN: Yup.string().required(l.IBAN.validationError),
    }),
    fopData: Yup.object().shape({
      FOPName: Yup.string().required(l.FOPName.validationError),
    }),
    tovData: Yup.object().shape({
      TOVName: Yup.string().required(l.TOVName.validationError),
      EDRPUO: Yup.string().required(l.EDRPUO.validationError),
    }),
  };

  const initialValues = {
    surname: '',
    name: '',
    middleName: '',
    nickName: '',
    email: '',
    phone: '',
    birthDate: '',
    telegram: '',
    whatsApp: '',
    oldPassword: '',
    password: '',
    passwordRepeat: '',
    identificationCode: '',
    passportSeries: '',
    passportRegistration: '',
    passportIssued: '',
    IBAN: '',
    FOPName: '',
    TOVName: '',
    EDRPUO: '',
    userType: 'privatePerson', // Default value
  };

  const onSubmit = async (values, { resetForm }) => {
    console.log(values);
  };
  return (
    <Wrapper>
    <BannerWrapper>
       <BannerImage src={BannerBg} alt="Banner"/>
       <BannerText>{l.banner}</BannerText>
       <BannerButton>
           <SvgIcon>
               <use href={`${svg}#icon-start-play`}/>
           </SvgIcon>
           {l.findOutMore}
         </BannerButton>
     </BannerWrapper>
   <Title>{l.settingTitle}</Title>
   <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
               <InputDataWrapper>

              <InputLabel>
              {l.surname.label}
              <InputField
                name="surname"
                type="text"
                placeholder={l.surname.placeholder}
                className={errors.surname && touched.surname ? 'error' : null}
              />
              <ErrorMessage name="surname" component="div" className="error" />
            </InputLabel>

              <InputLabel>
              {l.name.label}
              <InputField
                name="name"
                type="text"
                placeholder={l.name.placeholder}
                className={errors.name && touched.name ? 'error' : null}
              />
              <ErrorMessage name="name" component="div" className="error" />
            </InputLabel>
              {/* Add other fields here */}

              <InputLabel>
              {l.nickName.label}
              <InputField
                name="nickName"
                type="text"
                placeholder={l.nickName.placeholder}
                className={errors.nickName && touched.nickName ? 'error' : null}
              />
              <ErrorMessage name="nickName" component="div" className="error" />
             </InputLabel>

              {/* Add other fields here */}

              {/* Add other fields here */}

              <InputLabel>
              {l.email.label}
              <InputField
                name="email"
                type="email"
                placeholder={l.email.placeholder}
                className={errors.email && touched.email ? 'error' : null}
              />
              <ErrorMessage name="email" component="div" className="error" />  
             </InputLabel>
              {/* Add other fields here */}

              <InputLabel>
              {l.phone.label}
              <InputField
                name="phone"
                type="text"
                placeholder={l.phone.placeholder}
                className={errors.phone && touched.phone ? 'error' : null}
              />
              <ErrorMessage name="phone" component="div" className="error" />
             </InputLabel>
              {/* Add other fields here */}

              <InputLabel>
              {l.birthDate.label}
              
              <InputField
                name="birthDate"
                type="text"
                placeholder={l.birthDate.placeholder}
                className={errors.birthDate && touched.birthDate ? 'error' : null}
              />
              <ErrorMessage name="birthDate" component="div" className="error" />
                </InputLabel>
              {/* Add other fields here */}

              <InputLabel>
              {l.telegram.label}
              <InputField
                name="telegram"
                type="text"
                placeholder={l.telegram.placeholder}
                className={errors.telegram && touched.telegram ? 'error' : null}
              />
              <ErrorMessage name="telegram" component="div" className="error" />
             </InputLabel>
              {/* Add other fields here */}

              <InputLabel>
              {l.whatsApp.label}
              <InputField
                name="whatsApp"
                type="text"
                placeholder={l.whatsApp.placeholder}
                className={errors.whatsApp && touched.whatsApp ? 'error' : null}
              />
              <ErrorMessage name="whatsApp" component="div" className="error" />
              </InputLabel>
            </InputDataWrapper>


            <PersonalDataWrapper>
        <ButtonsWrapper>
          <button
            type="button"
            onClick={() => setFieldValue('privatePerson')}
            className={fieldValue === 'privatePerson' ? 'active' : ''}
          >
            {l.privatePerson}
          </button>
          <button
            type="button"
            onClick={() => setFieldValue('FOP')}
            className={fieldValue === 'FOP' ? 'active' : ''}
          >
            {l.FOP}
          </button>
          <button
            type="button"
            onClick={() => setFieldValue('TOV')}
            className={fieldValue === 'TOV' ? 'active' : ''}
          >
            {l.TOV}
          </button>
        </ButtonsWrapper>
        
        {fieldValue === 'privatePerson' && (
          <PrivatePersonWrapper>
            <InputLabel>{l.identificationCode.label}</InputLabel>
            <InputField
              name="identificationCode"
              type="text"
              placeholder={l.identificationCode.placeholder}
              className={errors.identificationCode && touched.identificationCode ? 'error' : null}
            />
            <ErrorMessage name="identificationCode" component="div" className="error" />

            {/* Додайте інші поля для приватного особи тут */}
          </PrivatePersonWrapper>
        )}
        {fieldValue === 'FOP' && (
          <FopInputs>
            <InputLabel>{l.FOPName.label}</InputLabel>
            <InputField
              name="FOPName"
              type="text"
              placeholder={l.FOPName.placeholder}
              className={errors.FOPName && touched.FOPName ? 'error' : null}
            />
            <ErrorMessage name="FOPName" component="div" className="error" />

            {/* Додайте інші поля для ФОП тут */}
          </FopInputs>
        )}
        {fieldValue === 'TOV' && (
          <TOVInputs>
            <InputLabel>{l.TOVName.label}</InputLabel>
            <InputField
              name="TOVName"
              type="text"
              placeholder={l.TOVName.placeholder}
              className={errors.TOVName && touched.TOVName ? 'error' : null}
            />
            <ErrorMessage name="TOVName" component="div" className="error" />

            <InputLabel>{l.EDRPUO.label}</InputLabel>
            <InputField
              name="EDRPUO"
              type="text"
              placeholder={l.EDRPUO.placeholder}
              className={errors.EDRPUO && touched.EDRPUO ? 'error' : null}
            />
            <ErrorMessage name="EDRPUO" component="div" className="error" />

            {/* Додайте інші поля для ТОВ тут */}
          </TOVInputs>
        )}
      </PersonalDataWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
    
  );
};

export default UserSetting;