import styled from 'styled-components';
export const BackdropHidden = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
width: 500px;
height: 100vh;
position: fixed;
top: -30px;
right: -400px;
overflow-y: auto;
background: #181818;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 40px;
border-radius: 10px;
box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
z-index:10;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-weight: 800;
  font-size: 22px;
  line-height: 164%;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: #fff;
`;

export const SubTitle = styled.div`
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #afafaf;
`;

export const Label = styled.label`
  position: relative;
  font-weight: 800;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
`;

export const Input = styled.input`
  width: 100%;
  background: #1c1c1c;
  border: none;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 24px;
  padding: 16px;
  color: var(--main-text-color);
  font-weight: 300;
  font-size: 16px;
  margin-top: 14px;

  &::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #9c9c9c;
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

export const SubmitBtn = styled.button`

  width: 100%;
  height: 50px;
  background: #1c1c1c;
  border: none;
  border-radius: 10px;
  font-weight: 800;
  font-size: 16px;
  line-height: 143%;
  cursor: pointer;
  color: #fff;
  transition: all 200ms ease-in-out;

  &:hover {
    background: #2b660f;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 15px;
  right: 95px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;

export const Errors = styled.div`
  width: 100%;
  text-transform: none;
  font-size: 12px;
  line-height: 105%;
  padding-left: 5px;
  position: absolute;
  top: 103px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--error-color);
`;

export const ResetPass = styled.span`
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 12px;
  color: yellow;
  cursor: pointer;
`;

export const EyeIcon = styled.svg`
  position: absolute;
  right: 15px;
  top: 58px;
  width: 20px;
  height: 20px;
  fill: #989898;
  cursor: pointer;

  &.eyeIconSelected {
    fill: var(--main-green-color);
  }
`;

export const AuthText = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 143%;
  margin-top: 30px;
  color: var(--second-text-color);
  text-align: center;
`;

export const AuthWithSocials = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  gap: 45px;
`;

export const SocialsItem = styled.div`
  position: relative;
`;

export const SocialsIcon = styled.svg`
  width: 32px;
  height: 32px;
  fill: #eaeaea;
  transition: all 0.2s linear;

  &:hover {
    fill: var(--main-green-color);
    cursor: pointer;
    transition: all 0.2s linear;
  }
`;

export const GoogleWrap = styled.div`
  position: absolute;
  opacity: 0;
`;
