import styled from "styled-components";

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 50px;
  z-index: 5;
  padding-right:30px;
  max-width: 1230px;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const HeaderCabinetWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right:30px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 2;
  background-color: rgba(32, 32, 32, 1);
`;

export const Logo = styled.img`
  position: absolute;
  left: 32px;
  max-height: 100%;
  margin: 40px 160px 34px 20px;
  z-index: 4;
`;

export const HeaderContent = styled.div`
  position: absolute;
  width: 75%;
  height: 100px;
  left:300px;
  margin-right: 55px;
  display: flex;
  background-color: rgba(32, 32, 32, 0.4);
  @media (max-width: 1200px) {
  }
`;

export const Text = styled.h1`
    position: absolute;
    z-index:3;
    opacity: 1;
    left: 10%;
    top: 20%;
    width:70%;
    color: rgba(234, 234, 234, 1);
    font-family: Rubik;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    @media (max-width: 1200px) {
      margin-top: 10px;
      width: 50%;
    }
`;

export const Button = styled.button`
  position: absolute;
  z-index:3;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20%;
  right: 10%;
  padding: 5px 10px;
  width: 150px;
  height: 50px;
  border-radius: 20px;
  background-color: rgba(32, 32, 32, 0.5);
  color: rgba(234, 234, 234, 1);
  cursor: pointer;
  @media (min-width: 768px) and (max-width: 1024px) {
    display:none;
  }
 
`;

export const LoginButton = styled.button`
  z-index:3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
  padding: 5px 10px;
  width: 96px;
  height: 56px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(234, 234, 234, 1);
  cursor: pointer;
  @media (max-width: 1200px) {
    z-index:3;
  }
`;

export const RegisterButton = styled.button`
  z-index:3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  padding: 5px 10px;
  width: 224px;
  height: 56px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  background-color: #3F9C14;
  color: rgba(234, 234, 234, 1);
  cursor: pointer;
  @media (max-width: 1200px) {
    z-index:3;
  }
`;

export const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const BackgroundImage = styled.img`
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  object-fit: cover;
`;

export const HeroImage = styled.img`
  position: absolute;
  left: 60%;
  z-index: 1;
  height: 100px;
  @media (max-width: 1200px) {
    z-index: 1;
    opacity:0.7;
  }
`;
