import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/SideBar/SideBar';
import CabinetHeader from '../components/Header/CabinetHeader';


const Admin = () => {
  return (
    <div className="admin">
      <Sidebar isAdmin={true} />
      <CabinetHeader/>
      <div className="admin-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
