
export const  lang = {
    ua: {
      title: "Приєднуйтесь до нашої реферальної програми!",
      text: "Почніть зараз заробляти!",
      button: "Начати зарабатывать",
    },
    ru: {
        title: "Присоединитесь к нашей реферальной программе!",
        text: "Начните зарабатывать прямо сейчас!",
        button: "Начать зарабатывать",
    },
    en: {
        title: "Join our referral program!",
        text: "Start earning now!",
        button: "Start Earning",
    },
  };
  

  