import React from 'react';
import Header from '../components/Header/Header';
import  HeadSection  from '../components/MainHeadSection/HeadSection';
import MainLevelSection from '../components/MainLevelsSection/MainLevelSection';
import MainInstructionSection from '../components/MainInstuctionSection/MainInstructionSection';
import Footer from '../components/Footer/Footer';
import MainFormSection from '../components/MainFormSection/MainFormSection';
import MainInfoSection from '../components/MainInfoSection/MainInfoSection';
import MainCallToActionSection from '../components/MainCallToActionSection/MainCallToActionSection';

const MainPage = () => {
    
    return (
      <>
      <Header/>
      <HeadSection/>
      <MainLevelSection/>
      <MainInstructionSection/>
      <MainFormSection/>
      <MainInfoSection/>
      <MainCallToActionSection/>
      <Footer/>
      </>
    );
};

export default MainPage;