export const lang = {
    ua: {
        title:["Заповніть форму", "і станьте нашим", "партнером"],
        text: ["Ми відправимо вам інструкцію та умови", "на пошту"],

        placeholders: {
            name: "Ім'я",
            city: "Місто",
            email: "Електронна пошта",
            phone: "Номер телефону",
            message: "Повідомлення (необов'язково)"
        },

        Btn: "ПОЧАТИ ЗАРОБЛЯТИ",

        notificationTitle:"Ми вже отримали ваш лист, ВОГОНЬ!",
        notificationMessage:"Зараз все прочитаємо і кинемо відповідь, трішечки почекати",
        
    },
    ru: {
        title: ["Заполните форму ", "и становитесь нашим", "партнером"],
        text: ["Мы отправим вам инструкцию и условия", "на почту"],
    
        placeholders: {
            name: "Имя",
            city: "Город",
            email: "Электронная почта",
            phone: "Номер телефона",
            message: "Сообщение(необязательно)"
        },
    
        Btn: "НАЧАТЬ ЗАРАБАТЫВАТЬ",

        notificationTitle:"Мы уже получили ваше письмо, АГОНЬ!",
        notificationMessage:"Сейчас мы все прочитаем и ответим. Подождите немного.",
    },
    en: {
        title: ["Fill out the form", "and become our", "partner"],
        text: ["We will send you instructions and conditions", "to your email"],
 
        placeholders: {
            name: "Name",
            city: "City",
            email: "Email",
            phone: "Phone Number",
            message: "Message (optional)"
        },

        Btn: "START EARNING",

        notificationTitle:"Oh, awesome! ",
        notificationMessage:"We will read and respond shortly. Please wait a bit",
    },
};