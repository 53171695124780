import React from "react";
// import Tablet from "../Assets/Group 483031.png"
import {  Awaiting, SectionContainer,} from "./MainInfoSection.styled";

 const MainInfoSection = () => {

  return (
    <SectionContainer>
     <Awaiting>QuestionSection Need info about content</Awaiting>
    </SectionContainer>
  );
};

export default MainInfoSection;