import styled from "styled-components";
import { motion } from "framer-motion";
import HeaderBackground from "../../images/Assets/Header_background.png"

export const SectionContainer = styled.section`
  position: relative;
  overflow: hidden;
  height: 800px;
  z-index: 1;
  top: 0;
  right: 0;
  background: url(${HeaderBackground}) center/cover no-repeat; 

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      -80deg,
      #181818 25%,
      rgba(24, 24, 24, 0.00) 66.32%,
      #181818 88.28%
    );
    background-size: cover;
    transform: scaleX(-1);
    pointer-events: none;
    z-index: 0;
  }

  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    width: 100%;
  }

  @media screen and (min-width: 220px) and (max-width: 1200px) {
    height: 680px;
    overflow: hidden;
    &:after {
      background: rgba(24, 24, 24, 0.6);
    }
  }
`;

export const ContentContainer = styled.div`
  max-width: 1180px;
  display: flex;
  flex-direction: row-reverse;
  margin: 100px auto; 
  align-items: center; 
  justify-content: space-between;
`;

export const MainImage = styled.img`
  z-index: 1;
  pointer-events: none;
  height: 550px;

  @media screen and (min-width: 750px) and (max-width: 1200px) {

  }
  
  @media screen and (min-width: 480px) and (max-width: 750px) {
    
  }
`;

export const TextOverlay = styled.div`
position: absolute;
top: 55%;
left: 50%;
transform: translate(-80%, -60%);
width: 600px;
z-index: 1;
transition: all 0.5s ease;

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    transform: translate(-50%, -50%);
  }

  @media screen and (min-width: 300px) and (max-width: 750px) {
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px auto;
    width: fit-content;
  }
`;

export const SlideTitle = styled(motion.h1)`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-family: Rubik;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.5s ease; 
  
  @media screen and (min-width: 750px) and (max-width: 1200px) {
    font-size: 26px;
    line-height: 28px;
  }

  @media screen and (min-width: 320px) and (max-width: 750px) {
    font-size: 20px;
    line-height: 25px;
    margin-left: 10px;
  }

  span {
    color: green; 
    display: block; 
  }
`;

export const SlideText = styled(motion.p)`
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(234, 234, 234, 0.6); 
  align-items: center;
  
  transition: all 0.5s ease;

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    font-size: 18px;
    line-height: 1px;

  }
  @media screen and (min-width: 320px) and (max-width: 750px) {
    font-size: 12px;
    line-height: 12px;
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-left: 10px;
    flex-wrap: wrap;
  }
`;

export const SlideSpan = styled(motion.span)`
font-family: Rubik;
font-size: 18px;
font-weight: 400;
line-height: 34px;
letter-spacing: 0em;
text-align: left;
margin: 0 4px;
width: 120px;
color: rgba(234, 234, 234, 1);

transition: all 0.5s ease; 

@media screen and (min-width: 750px) and (max-width: 1200px) {
  font-size: 16px;
  line-height: 1px;

}
@media screen and (min-width: 320px) and (max-width: 750px) {
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: rgba(234, 234, 234, 1);
  display: flex;
  flex-direction: row;
  margin: 0 4px 1px 4px;
  width:auto;
}

`;

export const ButtonContainer = styled(motion.div)`
display: flex; 
gap: 40px;
margin-top: 56px;

transition: all 0.5s ease; 

@media screen and (min-width: 750px) and (max-width: 1200px) {
  gap: 30px;
 margin-top: 46px;

}
  @media (max-width: 750px) {
    flex-direction: column;
    margin-top: 16px;
    gap: 20px;
  }

`
export const MainButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 10px;
  box-shadow: 
    0 20px 40px rgba(63, 156, 20, 0.3),
    inset 0 0 40px 40px rgba(63, 156, 20),
    inset 0 0 0 0 rgba(63, 156, 20);
  color: #eaeaea;
  cursor: pointer;
  font-size: 15px;
  font-weight: 800;
  height: 78px;
  line-height: 1.43;
  text-align: center;
  text-transform: uppercase;
 
  width: 274px;

  &:hover {
    box-shadow: 
      0 0 10px 0 rgba(63, 156, 20, 0.5) inset,
      0 0 10px 4px rgba(63, 156, 20, 0.5),
      inset 0 0 0 0 rgba(63, 156, 20, 0.5); 
      -webkit-transition: all 150ms ease-in-out;
  transition: box-shadow 200ms ease-in-out;
  }

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    width: 230px;
    font-size: 13px;
    font-weight: 800;
    height: 68px;
    line-height: 1;
  
  }
 
`;


export const DetailButton = styled.button`
  background-color: hsla(0, 0%, 9%, 0.7);
  border: 1px solid transparent;
  border-radius: 10px;
  color: #eaeaea;
  cursor: pointer;
  -webkit-filter: drop-shadow(0 20px 40px rgba(63, 156, 20, 0.3));
  filter: drop-shadow(0 20px 40px rgba(63, 156, 20, 0.3));
  font-size: 15px;
  font-weight: 800;
  height: 78px;
  line-height: 1.43;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  width: 235px;

  &:hover {
    background-color: #3f9c14;
  }
  
  @media screen and (min-width: 750px) and (max-width: 1200px) {
    width: 230px;
    font-size: 13px;
    font-weight: 800;
    height: 69px;
    line-height: 1;
  
  }
  @media (max-width: 750px) {
    width: 280px;
  }
`;
