import styled from "styled-components";

export const SectionContainer = styled.section`
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 880px;

  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    width: 100%;
  }

  @media screen and (min-width: 220px) and (max-width: 1200px) {
    height: 680px;
    overflow: hidden;
    &:after {
      background: rgba(24, 24, 24, 0.6);
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  flex: 1;
`;

export const TextContainer = styled.div`
  flex: 1;
  text-align: center;
  color: #fff;
`;

export const DudesImage = styled.img`
  width: 100%; /* Adjust the width as needed */
  height: auto;
`;

export const MinsImage = styled.img`
  width: 100%; /* Adjust the width as needed */
  height: auto;
`;

export const Title = styled.h1`
  font-size: 40px;
`;

export const Description = styled.p`
  font-size: 18px;
`;

export const ActionButton = styled.button`
  padding: 15px 30px;
  font-size: 16px;
  background-color: #3f9c14;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a163;
  }
`;
