import React, { useState } from "react";
import logo from '../../images/Assets/logoNormal.svg';
import { HeaderWrapper, 
    LoginButton, 
    Logo,
    RegisterButton} from "./Header.styled";
import LanguageSelect from "../languageSelect/languageSelect";
import { useTranslation } from "../../hooks/useTranslation";
import { lang } from "./lang";
import AuthModal from "../AuthModal/AuthModal";

const Header = () => {
  const l = useTranslation(lang)

  const [isModalOpen, setIsModalOpen] = useState(false); 

  const handleOpenModal = () => {
    setIsModalOpen(true); 
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  return (
    <HeaderWrapper>
      <Logo src={logo} alt="Logo Miraplay" />
      <LoginButton onClick={handleOpenModal}>{l.buttonLogin}</LoginButton>
      <RegisterButton>{l.buttonRegister}</RegisterButton>
      <LanguageSelect />
      {isModalOpen && <AuthModal onClose={handleCloseModal} />} 
    </HeaderWrapper>
  );
};

export default Header;
