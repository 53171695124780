import React from "react";
import { lang } from "./lang";
import { useTranslation } from "../../hooks/useTranslation";

import { SectionContainer,
     ContentContainer, 
     TextOverlay, 
     StepContainer, 
     StepNumber, 
     StepText } from "./MainInstructionSection.styled";

const MainInstructionSection = () => {
  const l = useTranslation(lang);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <SectionContainer
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <ContentContainer>
        <TextOverlay>
          <h1>{l.sectionTitle}</h1>
        </TextOverlay>

        <StepContainer>
          <StepNumber>{l.step1}</StepNumber>
          <StepText>{l.step1Description}</StepText>

          <StepNumber>{l.step2}</StepNumber>
          <StepText>{l.step2Description}</StepText>
          
          <StepNumber>{l.step3}</StepNumber>
          <StepText>{l.step3Description}</StepText>

        </StepContainer>
    
      </ContentContainer>
    </SectionContainer>
  );
};

export default MainInstructionSection;