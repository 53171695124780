export const lang = {
    ua: {
        title: ["грай з друзями і ", "заробляй великі ", "бонуси за реферальною", "системою"],
        text: ["Підключайте свої геймінгові ПК до Miraplay і", "заробляйте від 10 000 грн за кожне пристрій"],
        mainBtn: "ПОЧАТИ ЗАРОБЛЯТИ",
        secondBtn: "ДЕТАЛЬНІШЕ",
      },
    ru: {
        title: ["играй с друзьями и ", " зарабатывай большие ", " бонусы по реферальной", "системе"],
        text: ["Подключайте свои игровые РС к Miraplay и", "зарабатывайте от 10 000 грн за каждое устройство"],
        mainBtn: "НАЧАТЬ ЗАРАБАТЫВАТЬ",
        secondBtn: "ПОДРОБНЕЕ",
    },
    en: {
        title: ["play with friends and ", "earn big ", "bonuses through the", "referral system"],
        text: ["Connect your gaming PCs to Miraplay and", "earn from 10,000 UAH for each device"],
        mainBtn: "START EARNING",
        secondBtn: "LEARN MORE",
      },
};