import styled, {css} from 'styled-components';

 export const Wrapper = styled.div`
 position:absolute;
 top: 100px;
 left:300px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  padding: 1rem;
`;

export const BannerWrapper = styled.div`
  position: relative;
  width: 1030px;
  height: 200px;
  margin: 48px 39px 36px 69px;
  border: 1px 0px 0px 0px;
  background-position: center;
  display: flex;
  background-color:rgba(32, 32, 32, 0.6);
`;

export const BannerImage = styled.img`
  object-fit: cover;
  border-radius: 16px 0px 0px 0px;
  opacity: 0.7;
  position: absolute;
  z-index:-1;
`;
export const BannerButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 10%;
  left: 40%;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border:none;
  background-color: rgba(32, 32, 32, 1);
  color: rgba(234, 234, 234, 1);
  cursor: pointer;
  @media (max-width: 1200px) {
    z-index:3;
  }
`;

export const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
export const BannerText = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  z-index: 1;
  margin: auto;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

`;

export const  Title = styled.h1`
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-bottom: 32px;
  margin-left:70px;
  color:rgba(255, 255, 255, 1);
`;

export const  Features = styled.ul`
  display: flex;
  margin-left:70px;
  width: 100%;
  gap:20px;
  list-style: none;
  padding: 0;
`;

export const  FeatureItem = styled.li`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  width: 330px;
  height: 320px;
  background-color: rgba(22, 22, 22, 1);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
`;

export const  FeatureImage = styled.img`
  width: 100px;
  margin-bottom: 1rem;
`;

export const  FeatureTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left:22px;
  color:rgba(255, 255, 255, 1);
`;

export const  FeatureText = styled.p`
font-family: Rubik;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
margin-left:22px;
color:rgba(255, 255, 255, 0.7);

`;

export const  FeatureTextWrapper = styled.div`
${props =>
  props.second &&
  css`
    border-radius: 8px 0px 0px 0px;
    border-left: 3px solid ${props.active ? 'rgba(63, 156, 20, 1)' : 'rgba(181, 15, 45, 1)'};
    padding-left: 10px;
  `}
`;

export const FeatureContent = styled.div`
  display:none;
  ${props =>
    props.first &&
    css`
      display:flex;
      text-align: center;
      background-color: ${props.active ? 'rgba(63, 156, 20, 1)' : 'rgba(181, 15, 45, 1)'};
      width: 257px;
      height: 32px;
      border-radius: 8px;
      opacity: 0px;
      color: rgba(255, 255, 255, 1);
      flex-direction: column;
      padding: 8px;
    `}
`;

export const  FeatureNumber = styled.span`
font-family: Rubik;
font-size: 32px;
font-weight: 500;
line-height: 48px;
letter-spacing: -0.04em;
text-align: right;
color:rgba(255, 255, 255, 1);
margin-right:10px;
`;

export const  FeatureAccent = styled.span`
  margin-top: 0.5rem;
  text-align: right;
  color:rgba(255, 255, 255, 1);
  margin-left: 20px;

`;
export const  FeatureButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 22px;
  left:32px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border:none;
  background-color: rgba(32, 32, 32, 1);
  color: rgba(234, 234, 234, 1);
  cursor: pointer;
  @media (max-width: 1200px) {
    z-index:3;
}
`;
export const  LanguageSelect = styled.div`
  display: flex;
  align-items: center;
  background-color: #4d1087;
  color: #fff;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.3rem;

  ::before {
    margin-right: 1rem;
  }

  :hover {
    filter: brightness(0.9);
    ::before {
      filter: brightness(1);
    }
  }
`;