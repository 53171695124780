import React, { useRef } from "react";
import MainGif from "../../images/Assets/main.gif"
import { lang } from "./lang";
import {useTranslation} from "../../hooks/useTranslation";

import { SectionContainer, 
   TextOverlay, 
   SlideTitle,
   DetailButton, 
   ButtonContainer,
   SlideText,
   SlideSpan,
   MainImage,
   ContentContainer,
   MainButton} from "./HeadSection.styled";
import { HashLink } from "react-router-hash-link";




 const HeadSection = () => {
  const vidRef = useRef();
  const l = useTranslation(lang);

  const animation = {
    
    hidden: {
        y: -50,
        opacity: 0,
    },
    visible: (custom = 0) => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.15, duration: 1},

    }),
};

  return (
    <SectionContainer>
  
      <ContentContainer>
      <MainImage
      src={MainGif}
      ref={vidRef}
      alt="Main GIF"
      />
      
      <TextOverlay>
          <SlideTitle variants={animation}
                      initial="hidden"
                      animate="visible"
                      custom={4}>
          {l.title[0].toUpperCase()} <br/>
          {l.title[1].toUpperCase()} <br/>
          {l.title[2].toUpperCase()} <br/>
          {l.title[3].toUpperCase()}
          </SlideTitle>

          <SlideText variants={animation}
                      initial="hidden"
                      animate="visible"
                      custom={3}>
            {l.text[0]}
          </SlideText>

          <SlideText variants={animation}
                      initial="hidden"
                      animate="visible"
                      custom={2}>
            {l.text[1]} <SlideSpan>{l.text[2]}</SlideSpan> {l.text[3]}
          </SlideText>
          
          <ButtonContainer variants={animation}
                           initial="hidden"
                           animate="visible"
                           custom={1}>
          <HashLink smooth to="#partnerform" style={{ textDecoration: 'none' }}>
          <MainButton>{l.mainBtn}</MainButton>
          </HashLink>
          <HashLink smooth to="#demand">
            <DetailButton>{l.secondBtn}</DetailButton>
          </HashLink>
          </ButtonContainer>
      </TextOverlay>  
      
     </ContentContainer>
    </SectionContainer>
  );
};

export default HeadSection;