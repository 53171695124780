export const lang = {
  ua: {
    buttonText: 'Почати грати',
    buttonLogin: 'Вхід',
    buttonRegister: 'Зареєструватись',
    text: 'Привіт, ласкаво просимо на наш сайт! Якийсь дуже довгий текст з цікавою інформацією',
  },
  en: {
    buttonText: 'Start playing',
    buttonLogin: 'Login',
    buttonRegister: 'Register',
    text: 'Hello, welcome to our website! Some very long text with interesting information',
  },
  pl: {
    buttonText: 'Zacznij grać',
    buttonLogin: 'Zaloguj się',
    buttonRegister: 'Zarejestruj się',
    text: 'Witaj, witamy na naszej stronie! Jakiś bardzo długi tekst z ciekawą informacją',
  },
  ru: {
    buttonText: 'Начать играть',
    buttonLogin: 'Вход',
    buttonRegister: 'Регистрация',
    text: 'Привет, добро пожаловать на наш сайт! Какой-то очень длинный текст с интересной информацией',
  },
};
