export const lang = {
  ua: {
    myProfile: 'Мій профіль',
    banner: [
      'Баннер',
      'з',
      'довгим',
      'текстом',
      'Дізнатися',
      'більше',
    ],
    inactiveAccount: 'Аккаунт не активний',
    activeAccount: 'Аккаунт активний',
    userBalance: 'грн на рахунку',
    topUpBalance: ['Поповніть ваш рахунок на', 'грн.'],
    topUpNext: ['Наступне поповнення'],
    balance: 'Баланс',
    mrcBalance: 'MRC',
    inactiveBonus: [
      'Щоб мати можливість витрачати',
      'бонуси, поповніть рахунок',
    ],
    activeBonus: [
      'Ваш рахунок поповненно, виведення',
      'бонусів доступно',
    ],
    referrals: 'Реферали',
    invitedUsers: 'запрошених',
    activeUsers: ['Активних:', 'рефералів'],
    topUp: 'Поповнити рахунок',
    notEarning: 'Зачислення не активні',
    earning: 'Вивести бонуси',
    findOutMore: 'Дізнатися більше',
  },
  en: {
    myProfile: 'My Profile',
    banner: [
      'Banner',
      'with',
      'long',
      'text',
      'Learn',
      'more',
    ],
    inactiveAccount: 'Account is inactive',
    activeAccount: 'Account is active',
    userBalance: 'UAH on balance',
    topUpBalance: ['Top up your balance by', 'UAH.'],
    topUpNext: ['Next top-up'],
    balance: 'Balance',
    mrcBalance: 'MRC',
    inactiveBonus: [
      'To be able to spend',
      'bonuses, top up your account',
    ],
    activeBonus: [
      'Your account has been topped up, withdrawal',
      'of bonuses is available',
    ],
    referrals: 'Referrals',
    invitedUsers: 'invited',
    activeUsers: ['Active:', 'referrals'],
    topUp: 'Top up balance',
    notEarning: 'Accruals are inactive',
    earning: 'Withdraw bonuses',
    findOutMore: 'Learn more',
  },
  ru: {
    myProfile: 'Мой профиль',
    banner: [
      'Баннер',
      'с',
      'длинным',
      'текстом',
      'Узнать',
      'больше',
    ],
    inactiveAccount: 'Аккаунт не активен',
    activeAccount: 'Аккаунт активен',
    userBalance: 'грн на счету',
    topUpBalance: ['Пополните ваш счет на', 'грн.'],
    topUpNext: ['Следующее пополнение'],
    balance: 'Баланс',
    mrcBalance: 'MRC',
    inactiveBonus: [
      'Чтобы иметь возможность тратить',
      'бонусы, пополните счет',
    ],
    activeBonus: [
      'Ваш счет пополнен, вывод',
      'бонусов доступен',
    ],
    referrals: 'Рефералы',
    invitedUsers: 'приглашенных',
    activeUsers: ['Активных:', 'рефералов'],
    topUp: 'Пополнить счет',
    notEarning: 'Начисления не активны',
    earning: 'Вывести бонусы',
    findOutMore: 'Узнать больше',
  },
  pl: {
    myProfile: 'Mój profil',
    banner: [
      'Baner',
      'z',
      'długim',
      'tekstem',
      'Dowiedz',
      'się więcej',
    ],
    inactiveAccount: 'Konto jest nieaktywne',
    activeAccount: 'Konto jest aktywne',
    userBalance: 'grn na koncie',
    topUpBalance: ['Doładuj swoje konto o', 'grn.'],
    topUpNext: ['Następne doładowanie'],
    balance: 'Saldo',
    mrcBalance: 'MRC',
    inactiveBonus: [
      'Aby móc wydać',
      'bonusy, doładuj swoje konto',
    ],
    activeBonus: [
      'Twoje konto zostało doładowane, wypłata',
      'bonusów jest dostępna',
    ],
    referrals: 'Poleconych',
    invitedUsers: 'zaproszonych',
    activeUsers: ['Aktywni:', 'poleconych'],
    topUp: 'Doładuj saldo',
    notEarning: 'Naliczenia są nieaktywne',
    earning: 'Wypłać bonusy',
    findOutMore: 'Dowiedz się więcej',
  },
};
