export const lang = {
   ua: {
    title: ["Як це працює?"],
    text: ["Отримуйте до 40% за запрошених", "друзів і їх рефералів у системі ", "хмарного сервісу Miraplay.","Відстежуйте кількість рефералів та", "бонусів через свій особистий кабінет."],
    },
    ru: {
        title: ["Как это работает?"],
        text: ["Получайте до 40% за приглашенных", "друзей и их рефералов в системе ", "облачного сервиса Miraplay.","Отслеживайте кол-во рефералов и", "бонусов через свой личный кабинет."],
        
    },
    en: {
      title: ["How It Works"],
      text: ["Earn up to 40% for invited", "friends and their referrals in the", "Miraplay cloud service system.", "Track the number of referrals and", "bonuses through your personal account."],
      },
};