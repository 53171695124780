import { Field } from 'formik';
import styled, {css} from 'styled-components';

 export const Wrapper = styled.div`
 position:absolute;
 top: 100px;
 left:300px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  padding: 1rem;
`;

export const BannerWrapper = styled.div`
  position: relative;
  width: 1030px;
  height: 200px;
  margin: 48px 39px 36px 69px;
  border: 1px 0px 0px 0px;
  background-position: center;
  display: flex;
  background-color:rgba(32, 32, 32, 0.6);
`;

export const BannerImage = styled.img`
  object-fit: cover;
  border-radius: 16px 0px 0px 0px;
  opacity: 0.7;
  position: absolute;
  z-index:-1;
`;
export const BannerButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 10%;
  left: 40%;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border:none;
  background-color: rgba(32, 32, 32, 1);
  color: rgba(234, 234, 234, 1);
  cursor: pointer;
  @media (max-width: 1200px) {
    z-index:3;
  }
`;

export const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
export const BannerText = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  z-index: 1;
  margin: auto;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

`;
export const  Title = styled.h1`
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-bottom: 32px;
  margin-left:70px;
  color:rgba(255, 255, 255, 1);
`;
export const InputDataWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 1030px;
  height: 508px;
  gap: 10px;
  border-radius: 16px 0px 0px 0px;
  opacity: 0px;
  background-color: rgba(44, 44, 44, 1);
  margin-left: 70px;
  padding: 20px;
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
`;

export const InputField = styled(Field)`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  height: 30px;
  width: 260px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  &.error {
    border-color: #dc3545;
  }
`;

export const PersonalDataWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 1030px;
  height: 508px;
  gap: 10px;
  border-radius: 16px 0px 0px 0px;
  opacity: 0px;
  background-color: rgba(44, 44, 44, 1);
  margin-left: 70px;
  padding: 20px;
`;
export const ButtonsWrapper = styled.div`
  margin-bottom: 20px;
`;
export const PrivatePersonWrapper = styled.div`
  margin-bottom: 20px;
`;
export const FopInputs = styled.div`
  margin-bottom: 20px;
`;
export const TOVInputs = styled.div`
  margin-bottom: 20px;
`;