import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  max-width: 1180px;
  margin: 0 auto;
  @media screen and (max-width:780px){
    flex-direction: column; 
    height: 120px;
    margin: 60px 0 0 0;
  }
`;

export const LeftText = styled.p`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(109, 109, 109, 1);
  margin: 0;
`;

export const RightText = styled.p`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(175, 175, 175, 1);
  margin: 0;
`;
