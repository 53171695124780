import styled from "styled-components";
import InstructionBackground from "../../images/Assets/instructionback.png"
import { motion } from "framer-motion";

export const SectionContainer = styled(motion.section)`
  position: relative;
  overflow: hidden;
  height: 724px;
  background: url(${InstructionBackground}) center/cover no-repeat; 
  z-index: 1;
`;

export const ContentContainer = styled.div`
    max-width:1180px;
    height:100%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto; 
    align-items: flex-start; 
    justify-content: space-between;
   opacity: 75%;
`;

export const TextOverlay = styled.div`
  color: #fff;
  max-width: 600px;

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 1.5;
  }
`;

export const StepContainer = styled.div`
  color: #fff;
  width: 500px;
  height:100%;
  text-align: center;
  background-color:  rgba(21, 21, 21, 1);

`;

export const StepNumber = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const StepText = styled.p`
  font-size: 14px;
  line-height: 1.5;
`;
