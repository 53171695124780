export const lang = {
    ua: {
        sectionTitle:"Як приєднатися до реферальної програми?",
        step1: "Крок 1: Зареєструйтеся",
        step1Description: "Створіть обліковий запис на нашій платформі.",
        step2: "Крок 2: Діліться новиною з друзями",
        step2Description: "Розкажіть про нас друзям або в соцмережах, залишаючи особисте посилання-запрошення та QR-код.",
        step3: "Крок 3: Відстежуйте нараховані бонуси",
        step3Description: "Через свій особистий кабінет, де буде вся необхідна інформація."
    },
    ru: {
        sectionTitle:"Как присоединиться к реферальной программе?",
        step1: "Шаг 1: Зарегистрируйтесь",
        step1Description: "Создайте аккаунт на нашей платформе.",
        step2: "Шаг 2: Делитесь новостью с друзьями",
        step2Description: "Расскажите о нас друзьям или в своих соцсетях, оставляя личную ссылку-приглашение и QR-код.",
        step3: "Шаг 3: Отслеживайте начисленные бонусы",
        step3Description: "Через свой личный кабинет, где будет вся необходимая информация."
    },
    en: {
        sectionTitle:"How to join the referral program?",
        step1: "Step 1: Register",
        step1Description: "Create an account on our platform.",
        step2: "Step 2: Share the news with friends",
        step2Description: "Tell your friends about us or share in your social networks, leaving a personal invitation link and QR code.",
        step3: "Step 3: Track earned bonuses",
        step3Description: "Through your personal account, where all the necessary information will be available."
    }
};
