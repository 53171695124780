
import styled from "styled-components";


export const SectionContainer = styled.section`
  position: relative;
  overflow: hidden;
  z-index: 1;
  top: 0;
  right: 0;
  height: 680px;
  
  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    width: 100%;
  }

  @media screen and (min-width: 220px) and (max-width: 1200px) {
    height: 680px;
    overflow: hidden;
    &:after {
      background: rgba(24, 24, 24, 0.6);
    }
  }
`;

export const Awaiting = styled.h1`
 color: #fff;
 font-size: 40px;
`