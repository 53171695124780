import { motion } from "framer-motion";
import styled from "styled-components";


export const SectionContainer = styled.section`
  position: relative;
  overflow: hidden;
  z-index: 1;
  top: 0;
  right: 0;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: radial-gradient(50% 50% at 100% 0%, #3F9C14 0%, rgba(63, 156, 20, 0) 100%);
    background-size: cover;
    pointer-events: none;
    z-index: -1;
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      -80deg,
      #181818 25%,
      rgba(24, 24, 24, 0.00) 66.32%,
      #181818 88.28%
    );
    background-size: cover;
    transform: scaleX(-1);
    pointer-events: none;
    z-index: 0;
  }

  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    width: 100%;
  }

  @media screen and (min-width: 220px) and (max-width: 1200px) {
    height: 680px;
    overflow: hidden;
    &:after {
      background: rgba(24, 24, 24, 0.6);
    }
  }
`;

export const ContentContainer = styled.div`
  max-width:1180px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin: 0 auto; 
  align-items: flex-start; 
  justify-content: space-between;

  

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    &:before {
      width: 40%;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 750px) {
    &:before {
      width: 60%;
    }
  }
`;

export const TextOverlay = styled.div`
  position: absolute;
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 600px;
  z-index: 1;
  transition: all 0.5s ease;

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    width: 80%;
    max-width: 600px;
  }

  @media screen and (min-width: 300px) and (max-width: 750px) {
    width: 80%;
    max-width: 600px;
  }
`;

export const SlideText = styled(motion.h1)`
  margin-bottom: 20px;
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.5s ease; 
  width: 818px;
  @media screen and (min-width: 750px) and (max-width: 1200px) {
    font-size: 26px;
    line-height: 28px;
  }

  @media screen and (min-width: 320px) and (max-width: 750px) {
    font-size: 20px;
    line-height: 25px;
    margin-left: 10px;
  }

  span {
    color: green; 
    display: block; 
  }
`;

export const SlideTitle = styled(motion.p)`
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(234, 234, 234, 0.6); 
  align-items: center;
  
  transition: all 0.5s ease;

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    font-size: 18px;
    line-height: 1px;
  }

  @media screen and (min-width: 320px) and (max-width: 750px) {
    font-size: 12px;
    line-height: 12px;
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-left: 10px;
    flex-wrap: wrap;
  }
`;

export const MainImage = styled.img`
  margin-top:150px;
  pointer-events: none;
  height: 600px;
  

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    height: 400px;
  }
  
  @media screen and (min-width: 480px) and (max-width: 750px) {
    height: 300px;
  }
`;

export const TabletImage = styled.img`
  z-index: 1;
  pointer-events: none;
  height: 250px;

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    height: 180px;
  }
  
  @media screen and (min-width: 480px) and (max-width: 750px) {
    height: 120px;
  }
`;
